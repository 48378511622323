.wid-500 {
    max-width: 500px;
}
.vh-75 {
    height: 75vh;
}

.main-header {
    display: flex;
    justify-content: center;
    font-size: 2.5rem;
    font-weight: 800;
    margin-top: 2.5rem;
}

.section-header {
    display: flex;
    justify-content: center;
    font-size: 2rem;
    font-weight: 600;
    margin-top: 5rem;
}

.subtitle-text {
    font-size: 1rem;
    font-weight: 100;
    text-align: center;
}

.button-section {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-top: 3rem;
}

.how-to-section-steps {
    margin-top: 4rem;
    display: flex;
    flex-direction: row;
    gap: 7rem;
    align-items: center;
}

.steps-section {
    flex: 1;
}

.main-container {
    min-height: 100vh;
}

@media (max-width: 1200px) {
    .how-to-section-steps {
        gap: 4rem;
    }
}

@media (max-width: 768px) {
    .button-section {
        width: 100%;
    }

    .main-header {
        margin-top: 2rem;
    }

    .how-to-section-steps {
        flex-direction: column;
        gap: 1rem;
    }
}

@media (max-width: 576px) {
    .wid-500 {
        max-width: 80%;
    }
}
