.username-box {
    word-break: break-all;
}

.text-justify {
    text-align: center;
}

@media (max-width: 576px) {
    .session-id {
        font-size: medium;
    }
}
