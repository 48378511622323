.vote-btn {
    width: 125px;
    height: 200px;
}

.vote-btn-font-size {
    font-size: 65px;
}

@media (max-width: 1400px) {
    .vote-btn {
        width: 100px;
        height: 150px;
    }

    .vote-btn-font-size {
        font-size: 50px;
    }
}

@media (max-width: 992px) {
    .vote-btn {
        width: 100px;
        height: 150px;
    }

    .vote-btn-font-size {
        font-size: 50px;
    }
}

@media (max-width: 576px) {
    .vote-btn {
        width: 5rem;
        height: 6rem;
    }

    .vote-btn-font-size {
        font-size: 2rem;
    }
}
