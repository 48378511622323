.notification-box {
    position: fixed;
    bottom: 0;
    display: flex;
    flex-direction: column-reverse;
    overflow: hidden;
    margin-bottom: 7rem;
    margin-left: 2rem;
    width: 30%;
    max-width: 250px;
    z-index: 1031;
}
