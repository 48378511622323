.notification {
    border: 1px solid;
    border-radius: 3px;
    margin-top: 0.5rem;
    padding: 0.2rem 0.2rem 0.2rem 0.2rem;
    min-width: 90px;
    z-index: 1031;
}

.notification-success {
    background-color: #d4edda;
    border-color: #c3e6cb;
}

.notification-error {
    background-color: #f8d7da;
    border-color: #f5c6cb;
}

.notification-default {
    background-color: #cce5ff;
    border-color: #b8daff;
}

.hide {
    opacity: 0;
    transition: opacity 400ms;
}

@media (max-width: 576px) {
    .notification {
        font-size: small;
    }
}
